<template>
  <footer>
    <div class="gradient-separator"></div>
    <div class="container">
      <div class="footer-content">
        <p class="copyright">&copy; {{ currentYear }} Ara Platforms</p>
        <div class="footer-links">
          <a href="#" @click.prevent="$emit('show-privacy-modal')">Privacy Policy</a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'FooterComponent',
  data() {
    return {
      currentYear: new Date().getFullYear()
    }
  }
}
</script>

<style scoped>
footer {
  background: white;
  padding: 20px 0;
  text-align: center;
}

.gradient-separator {
  height: 2px;
  background: linear-gradient(90deg, #4A7A9D, #A3D5E0, #F8C2C9);
  margin: 0 20px;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
}

.copyright {
  font-size: 14px;
  color: #333;
}

/* .brand-text {
  font-weight: bold;
  color: #4A7A9D;
} */

.footer-links a {
  color: #4A7A9D;
  text-decoration: none;
  font-size: 14px;
  transition: color 0.3s ease;
}

.footer-links a:hover {
  color: #A3D5E0;
}

@media (max-width: 600px) {
  .footer-content {
    flex-direction: column;
    gap: 10px;
  }
}
</style>