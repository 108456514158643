<template>
  <div class="contact-container">
    <div class="contact-content">
      <h2 v-scroll-animation class="contact-title">Get in Touch</h2>
      <p v-scroll-animation class="contact-text">
        Ready to transform how your organization leverages data? We'd love to hear from you. Email us at hello@araplatforms.com
        <font-awesome-icon 
          class="copy-icon" 
          :icon="copied ? 'check' : 'copy'"
          @click="copyEmail"
        />
        .
      </p>
    </div>
  </div>
</template>

<script>
import { scrollAnimation } from '@/directives/scrollAnimation'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCopy, faCheck } from '@fortawesome/free-solid-svg-icons'

library.add(faCopy, faCheck)

export default {
  name: 'ContactComponent',
  components: {
    FontAwesomeIcon
  },
  directives: {
    'scroll-animation': scrollAnimation
  },
  data() {
    return {
      copied: false
    }
  },
  methods: {
    copyEmail() {
      navigator.clipboard.writeText('hello@araplatforms.com')
      this.copied = true
      setTimeout(() => {
        this.copied = false
      }, 2000)
    }
  }
}
</script>

<style scoped>
.contact-container {
  padding: 120px 20px;
  min-height: 60vh;
  display: flex;
  align-items: center;
}

.contact-content {
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
}

.contact-title {
  font-family: 'Crimson Pro', serif;
  font-size: 4rem;
  margin-bottom: 2rem;
  color: #1f2937;
}

.contact-text {
  font-family: 'Inter', sans-serif;
  font-size: 1.25rem;
  color: #1f2937;
  line-height: 1.8;
  max-width: 600px;
  margin: 0 auto;
}

.copy-icon {
  color: #4A7A9D;
  cursor: pointer;
  margin: 0 0.25rem;
  transition: all 0.2s ease;
}

.copy-icon:hover {
  opacity: 0.8;
}

.email-link {
  color: #4A7A9D;
  text-decoration: none;
  transition: all 0.2s ease;
}

.email-link:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .contact-container {
    padding: 80px 20px;
  }

  .contact-title {
    font-size: 2.5rem;
  }

  .contact-text {
    font-size: 1rem;
  }
}
</style>
