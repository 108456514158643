<template>
    <section id="team" class="team">
      <div class="container">
        <h2 v-scroll-animation>Meet Our Team</h2>
        <div class="team-members">
          <div v-scroll-animation class="team-member">
            <img src="@/assets/img/kyle_photo.jpg" alt="Kyle Johnson">
            <h3>Kyle Johnson</h3>
            <div class="social-links">
              <a href="https://www.linkedin.com/in/gkjohns" target="_blank" title="LinkedIn"><i
                  class="fab fa-linkedin"></i></a>
              <a href="https://www.kylejohnson.ai" target="_blank" title="Personal Website"><i
                  class="fas fa-globe"></i></a>
              <a href="https://twitter.com/kyledotai" target="_blank" title="Twitter"><i class="fab fa-twitter"></i></a>
            </div>
            <p>Co-Founder, Product</p>
            <p class="bio">Ex-Facebook data scientist with 8 years of experience in applied machine learning, AI, and
              social media analytics.
              Kyle brings a wealth of
              experience from Meta's Reality Labs and Facebook AI Research (FAIR), focusing on trust, integrity, and
              machine
              translation.</p>
          </div>
          <div v-scroll-animation class="team-member">
            <img src="@/assets/img/mari_photo.jpg" alt="Mari Soonsoo Bae">
            <h3>Mari Soonsoo Bae</h3>
            <div class="social-links">
              <a href="https://www.linkedin.com/in/mari-bae" target="_blank" title="LinkedIn"><i
                  class="fab fa-linkedin"></i></a>
              <a href="https://www.maribae.com" target="_blank" title="Personal Website"><i
                  class="fas fa-globe"></i></a>
            </div>
            <p>Co-Founder, Growth</p>
            <p class="bio">Mari brings 12 years of business development and product operations experience, including launching a $3B program at Google. With an MBA from the University of Washington, she excels in strategy execution, process optimization, and data-driven growth.</p>
          </div>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  import { scrollAnimation } from '@/directives/scrollAnimation'

  export default {
    name: 'TeamComponent',
    directives: {
      'scroll-animation': scrollAnimation
    }
  }
  </script>
  
  <style scoped>
  .team {
    padding: 60px 0;
    background: #fff;
  }
  
  .team h2 {
    text-align: center;
    font-size: 36px;
    margin-bottom: 40px;
    color: #4A7A9D;
  }
  
  .team-members {
    display: flex;
    justify-content: center;
    gap: 140px;
  }
  
  .team-member {
    text-align: center;
    max-width: 400px;
  }
  
  .team-member img {
    width: 300px;
    height: 300px;
    object-fit: cover;
    border-radius: 24px;
    margin-bottom: 20px;
    transition: all 0.3s ease;
    box-shadow: 0 5px 15px rgba(74, 122, 157, 0.2);
  }
  
  /* Enhanced hover effect */
  .team-member img:hover {
    transform: scale(1.03);
    box-shadow: 0 12px 30px rgba(74, 122, 157, 0.25);
  }
  
  .team-member .bio {
    font-size: 14px;
    color: #666;
    margin-bottom: 15px;
    text-align: center;
  }
  
  .team-member p {
    margin-bottom: 10px;
  }
  
  .social-links a {
    color: #4A7A9D;
    margin: 0 5px;
    transition: color 0.3s ease;
  }
  
  .social-links a:hover {
    color: #A3D5E0;
  }
  
  @media (max-width: 768px) {
    .team-members {
      flex-direction: column;
      align-items: center;
    }
  
    .team-member {
      width: 100%;
      max-width: 450px;
      margin-bottom: 30px;
    }
  
    .team-member img {
      width: 360px;
      height: 360px;
    }
  }
  
  /* Option 1: Dots */
  .section-divider-dots {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin: 0 auto;
  }
  
  .section-divider-dots span {
    width: 8px;
    height: 8px;
    border-radius: 50%;
  }
  
  .section-divider-dots span:nth-child(1) {
    background: #4A7A9D;
  }
  
  .section-divider-dots span:nth-child(2) {
    background: #A3D5E0;
  }
  
  .section-divider-dots span:nth-child(3) {
    background: #F8C2C9;
  }
  
  /* Option 2: Dashed */
  .section-divider-dash {
    width: 200px;
    height: 2px;
    margin: 0 auto;
    background: repeating-linear-gradient(
        90deg,
        #4A7A9D 0,
        #4A7A9D 20px,
        transparent 20px,
        transparent 30px,
        #A3D5E0 30px,
        #A3D5E0 50px,
        transparent 50px,
        transparent 60px,
        #F8C2C9 60px,
        #F8C2C9 80px
    );
  }
  
  /* Option 3: Gradient fade */
  .section-divider-fade {
    width: 300px;
    height: 1px;
    margin: 0 auto;
    background: linear-gradient(
        90deg,
        transparent,
        #4A7A9D 20%,
        #A3D5E0 50%,
        #F8C2C9 80%,
        transparent
    );
  }
  
  /* Animation classes */
  .scroll-animate {
    opacity: 0;
    transform: translateY(30px);
    transition: all 0.6s ease-out;
  }
  
  .scroll-animate-active {
    opacity: 1;
    transform: translateY(0);
  }
  
  /* Delay second team member slightly */
  .team-member:nth-child(2) {
    transition-delay: 0.2s;
  }
  </style>