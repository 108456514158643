<template>
  <div class="page-container">
    <main class="main-content">
      <div class="content-sections">
        <h3 v-scroll-animation class="section-title">Our Mission</h3>
        <p v-scroll-animation class="section-description">
          At Ara Platforms, we're building the infrastructure for seamless analytics collaboration. Our mission is to 
          transform how organizations leverage their data teams, turning scattered insights into a unified source of 
          truth that drives decisions.
        </p>

        <h3 v-scroll-animation class="section-title">The Problem We're Solving</h3>
        <p v-scroll-animation class="section-description">
          Even at world-class companies, data teams face a systemic challenge: analysts spend 50% of their time 
          responding to ad hoc requests with unclear business value, while valuable insights remain buried in private 
          notebooks and Slack threads. Data science managers struggle to prioritize competing demands, and executives 
          make critical decisions based on outdated analyses because the latest ones are impossible to find.
        </p>

        <h3 v-scroll-animation class="section-title">Why We Started Ara</h3>
        <p v-scroll-animation class="story-text">
          Our founders experienced this firsthand at Meta and Google. Despite having exceptional data teams, 
          growing headcount often made decision-making slower, not faster. The more analysts they hired, 
          the more fragmented their insights became. They realized that the challenge wasn't data access 
          – it was insight delivery and collaboration.
        </p>

        <h3 v-scroll-animation class="section-title">What We're Building</h3>
        <p v-scroll-animation class="story-text">
          We're building an intelligent collaboration platform that transforms how teams work with data. 
          Our platform combines AI-powered interfaces with comprehensive workflow tools that help teams discover, 
          reuse, and deliver impactful analyses. From question to insight to action, we're creating the 
          infrastructure that makes world-class analytics truly accessible.
        </p>

        <blockquote v-scroll-animation class="highlight-quote">
          When insights are discoverable and reusable, data teams can focus on strategic impact instead of 
          repetitive requests.
        </blockquote>

        <p v-scroll-animation class="story-text">
          We're at the start of this journey, working closely with leading data teams to shape our product. 
          If you share our vision for transforming how organizations leverage their analytics capabilities, 
          we'd love to talk.
        </p>
      </div>
    </main>
  </div>
</template>

<script>
import { scrollAnimation } from '@/directives/scrollAnimation'

export default {
  name: 'LandingPage',
  directives: {
    'scroll-animation': scrollAnimation
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Crimson+Pro:wght@400;600&family=Inter:wght@400;500;600&display=swap');

.font-serif {
  font-family: 'Crimson Text', serif;
}

.page-container {
  min-height: 100vh;
  padding-bottom: 32px;
  background-color: white;
}

.main-content {
  max-width: 1024px;
  margin: 0 auto;
  padding: 5px 96px;
}

.content-sections {
  margin: 5px 0;
  font-family: 'Crimson Text', serif;
}

.content-section {
  margin-top: 0;
  margin-bottom: 128px;
}

.section-title {
  font-size: 2rem;
  margin: 1.5rem 0 0.5rem;
  font-family: 'Crimson Pro', serif;
  font-weight: 600;
}

.section-description,
.story-text {
  font-size: 1rem;
  line-height: 1.75;
  color: #1f2937;
  font-family: 'Inter', sans-serif;
}

.story-content {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.highlight-quote {
  font-family: 'Crimson Pro', serif;
  font-size: 1.25rem;
  line-height: 1.6;
  color: #1f2937;
  font-style: italic;
  position: relative;
  padding: 2rem 3rem;
  margin: 2rem 0;
  border-left: 6px solid;
  border-image: linear-gradient(to bottom, #4A7A9D, #A3D5E0, #F8C2C9) 1;
  background-color: #f8f9fa;
}

.highlight-quote::before {
  content: '"';
  font-size: 4rem;
  position: absolute;
  top: -1rem;
  left: 0.5rem;
  opacity: 0.2;
}

@media (max-width: 768px) {
  .main-content {
    padding: 5px 24px;
  }

  .section-title {
    font-size: 1.75rem;
  }

  .section-description,
  .story-text {
    font-size: 0.875rem;
  }

  .highlight-quote {
    font-size: 1.125rem;
    padding: 1.5rem 2rem;
  }
}

/* Animation classes */
.scroll-animate {
  opacity: 0;
  transform: translateY(30px);
  transition: all 0.6s ease-out;
}

.scroll-animate-active {
  opacity: 1;
  transform: translateY(0);
}

.story-text+.story-text {
  transition-delay: 0.2s;
}

.highlight-quote {
  transition-delay: 0.3s;
}

.section-description+.section-description {
  transition-delay: 0.2s;
}

.bullet-list {
  list-style-type: disc;
  padding-left: 48px;
}
</style>