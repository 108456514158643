import { createApp } from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faBrain, faBullseye, faSyncAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { inject } from '@vercel/analytics';



// Add FontAwesome icons
library.add(faBrain, faBullseye, faSyncAlt)

// Import the main App component
import App from './App.vue'

const app = createApp(App)

// Initialize vercel analytics
inject()

app.component('font-awesome-icon', FontAwesomeIcon)
app.mount('#app')
