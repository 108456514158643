export const scrollAnimation = {
  mounted: (el, binding) => {
    const options = {
      root: null,
      threshold: 0.2,
      ...binding.value
    }

    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        el.classList.add('scroll-animate-active')
        observer.unobserve(el)
      }
    }, options)

    el.classList.add('scroll-animate')
    observer.observe(el)
  }
} 