<template>
    <div class="modal" v-if="isVisible">
      <div class="modal-content">
        <span class="close" @click="close">&times;</span>
        <h2>Privacy Policy</h2>
        <p>At Ara, we value your privacy and are committed to protecting your personal information. This policy outlines
          how we collect, use, and safeguard your data:</p>
        <ul>
          <li>We collect only necessary information to provide our services.</li>
          <li>Your data is securely stored and never sold to third parties.</li>
          <li>We use industry-standard security measures to protect your information.</li>
          <li>You can request to view, modify, or delete your data at any time.</li>
        </ul>
        <p>For more information, please contact us at hello@araplatforms.com</p>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'PrivacyModal',
    props: {
      isVisible: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      close() {
        this.$emit('close')
      }
    }
  }
  </script>
  
  <style scoped>
  .modal {
    display: flex;
    position: fixed;
    z-index: 1001;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
    align-items: center;
    justify-content: center;
  }
  
  .modal-content {
    background-color: #fefefe;
    padding: 30px;
    border: 1px solid #888;
    width: 80%;
    max-width: 600px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: 'Inter', sans-serif;
    color: #333;
    position: relative;
  }
  
  .modal-content h2 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #4A7A9D;
  }
  
  .modal-content p,
  .modal-content ul {
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 15px;
  }
  
  .modal-content ul {
    padding-left: 20px;
  }
  
  .close {
    color: #aaa;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
    transition: color 0.3s ease;
    position: absolute;
    top: 10px;
    right: 20px;
  }
  
  .close:hover,
  .close:focus {
    color: #4A7A9D;
    text-decoration: none;
  }
  </style>