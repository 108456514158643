<template>
  <header>
    <nav class="container">
      <div class="logo">
        <a href="#"><img src="@/assets/img/logo_name.png" alt="Ara"></a>
      </div>
      <ul>
        <li v-for="item in navItems" :key="item.name">
          <a :href="item.href" class="nav-link">{{ item.name }}</a>
        </li>
      </ul>
    </nav>
    <div class="gradient-line-container">
      <div class="gradient-line"></div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'HeaderComponent',
  inject: ['navItems']
}
</script>

<style scoped>
header {
  background: white;
  padding: 0px 0;
  height: 60px;
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

nav ul {
  display: flex;
  list-style: none;
}

nav ul li a {
  text-decoration: none;
  color: #4A7A9D;
  padding: 12px 20px;
  transition: all 0.3s ease;
  font-weight: 500;
  position: relative;
}

nav ul li a:hover {
  color: #A3D5E0;
}

nav ul li a::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 50%;
  background: linear-gradient(90deg, #A3D5E0, #F8C2C9);
  transition: all 0.3s ease;
  transform: translateX(-50%);
}

nav ul li a:hover::after {
  width: 80%;
}

.logo {
  cursor: pointer;
}

.logo img {
  max-height: 50px;
  width: auto;
}

.gradient-line-container {
  margin-top: 5px;
  padding: 0 20px;
}

.gradient-line {
  height: 1px;
  background: linear-gradient(90deg, #4A7A9D, #F8C2C9);
}

@media (max-width: 768px) {
  nav ul {
    display: flex;
    gap: 10px;
  }
  
  nav ul li a {
    padding: 8px 12px;
    font-size: 0.9em;
  }
}
</style>