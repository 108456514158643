<template>
  <div id="app">
    <HeaderComponent />
    <HeroComponent />
    <FeaturesComponent id="features" />
    <div class="section-divider-dots">
      <span></span>
      <span></span>
      <span></span>
    </div>
    
    <TeamComponent id="team" />
    
    <div class="section-divider-dots">
      <span></span>
      <span></span>
      <span></span>
    </div>
    
    <OurStoryComponent id="story" />
    
    <div class="section-divider-dots">
      <span></span>
      <span></span>
      <span></span>
    </div>
    
    <ContactComponent id="contact" />
    <FooterComponent @show-privacy-modal="showPrivacyModal" />
    <PrivacyModal 
      :isVisible="isPrivacyModalVisible" 
      @close="hidePrivacyModal" 
    />
  </div>
</template>

<script>
import HeaderComponent from './components/HeaderComponent.vue'
import HeroComponent from './components/HeroComponent.vue'
import OurStoryComponent from './components/OurStoryComponent.vue'
import TeamComponent from './components/TeamComponent.vue'
import FooterComponent from './components/FooterComponent.vue'
import PrivacyModal from './components/PrivacyModal.vue'
import FeaturesComponent from './components/FeaturesComponent.vue'
import ContactComponent from './components/ContactComponent.vue'

export default {
  name: 'App',
  components: {
    HeaderComponent,
    HeroComponent,
    OurStoryComponent,
    TeamComponent,
    FooterComponent,
    PrivacyModal,
    FeaturesComponent,
    ContactComponent
  },
  data() {
    return {
      isPrivacyModalVisible: false,
      navItems: [
        { name: 'Features', href: '#features' },
        { name: 'Team', href: '#team' },
        { name: 'Our Story', href: '#story' }
      ]
    }
  },
  methods: {
    showPrivacyModal() {
      this.isPrivacyModalVisible = true;
    },
    hidePrivacyModal() {
      this.isPrivacyModalVisible = false;
    }
  },
  provide() {
    return {
      navItems: this.navItems
    }
  }
}
</script>

<style>
/* Global styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Inter', sans-serif;
  line-height: 1.6;
  color: #333;
}

.container {
  width: 90%;
  max-width: 1200px;
  margin: auto;
  padding: 10px;
}

.section-divider-dots {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin: 50px auto;
}

.section-divider-dots span {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.section-divider-dots span:nth-child(1) {
  background: #4A7A9D;
}

.section-divider-dots span:nth-child(2) {
  background: #A3D5E0;
}

.section-divider-dots span:nth-child(3) {
  background: #F8C2C9;
}
</style>
